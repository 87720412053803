import React, { useState } from 'react';
import Breadcrumb from '../templates/Breadcrumb';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const PerfectPlayer = () => {

    const navigate = useNavigate();
    
   
    const breadcrumbItems = [
        { label: 'Back to home', to: '/' }
    ];


    const [question, setQuestion] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        navigate({
            pathname: '/scoutassist',
            search: `?question=${question}`,
        });

    }
    return (
        <div>
            <Breadcrumb items={breadcrumbItems} back={true}/>
            <div className='d-flex flex-column align-items-md-center'>
                <div className='d-flex flex-column justify-content-md-center justify-content-end vh-80 max-w-f-600'>
                    <h4 className='mb-md-5 mb-3 fw-extra-bold'>Find your perfect player</h4>
                    <div className="d-flex overflow-scroll overflow-md-visible d-grid-md  grid-gap-3 grid-md-2-cols mb-8 pb-2 pb-md-0 position-relative" >
                        <Link to={'/scoutassist_basic/1'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.
                            </p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </Link>
                        <Link to={'/scoutassist_basic/2'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong></p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </Link>
                        <Link to={'/scoutassist_basic/3'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </Link>
                        <Link to={'/scoutassist_basic/4'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </Link>  
                    </div>                  
                    <div>
                        
                        <form onSubmit={handleSubmit}>
                            <div className="btn-group focus-dark active w-100">
                                <div className="btn btn-link border-1 py-3 pe-2 border-neutral-gray-900 rounded-pill rounded-top-end-0 border-end-0 rounded-bottom-end-0 bg-dark-mode-50  small position-relative center-content"><div className='bg-fluoro-green-500 rounded-circle badge mb-0'><i className="fa-regular  fa-sparkles "></i></div></div>
                                <input type="text"  className="py-25 px-2 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-neutral-gray-900 rounded-pill form-control form-control-lg rounded-top-start-0 ro rounded-top-end-0 rounded-bottom-start-0 rounded-bottom-end-0" placeholder="Find your perfect player..."  required value={question} onChange={(e) => setQuestion(e.target.value)}  />
                                <button type="submit" className="btn btn-link border-1 border-neutral-gray-900 rounded-pill rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa fa-arrow-right text-fluoro-green-400"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
          
        </div>
    )
}

export default PerfectPlayer