import React, { useEffect, useState, useCallback } from 'react';
import PlayerCard from '../templates/PlayerCard';
import { apiService } from '../services/apiService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../templates/Pagination';
import Filters from './Filters';
import { Modal, Spinner, Offcanvas } from "react-bootstrap";


const ScoutAssistSearch = () => {

    const [searchParams] = useSearchParams();


    const navigate = useNavigate();

    const handleCardClick = (player) => {
        navigate(`/playerdetail/${player.uuid}`);
    };

    const [loadingData, setLoadingData] = useState(false);
    const [results, setResults] = useState([]);
    const [exportLink, setExportLink] = useState([]);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    // const [orderBy, setOrderBy] = useState('Position');
    const [visibleCards, setVisibleCards] = useState(10);
    const [showFilterOffCanvas, setShowFilterOffCanvas] = useState(false);

    const [question, setQuestion] = useState('');

    const handleCloseFilterOffCanvas = () => setShowFilterOffCanvas(false);
    const handleShowFilterOffCanvas = () => setShowFilterOffCanvas(true);

    const performSearch = useCallback(async (theQuestion) => {

        setLoadingData(true);

        let data = new FormData();
        data.append('question', theQuestion);



        apiService.apiPostForm(`${process.env.REACT_APP_API_URL}/scout_assist/question_search`, data)
            .then((resp) => {

                setResults(resp.data);
                setExportLink(resp.export_link)
                setResultsTotal(resp.data.length);
                setPagesTotal(Math.ceil(resp.data.length / pageSize));
                setLoadingData(false);
            })
            .catch((error) => {
                setResults([]);
                setLoadingData(false);
            });
    }, [pageSize]);



    useEffect(() => {
        const theQuestion = searchParams.get('question');

        if (theQuestion) {
            setQuestion(theQuestion);
            performSearch(theQuestion);
        }


    }, [searchParams, performSearch]);

    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 10));
    };

    const orderByEventHandler = (event) => {
        setPage(1);
        // setOrderBy(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        performSearch(question);
        window.history.replaceState({}, '', '/scoutassist?question=' + question);

    }

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 10);
    };

    const currentPageResults = results.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = results.slice(0, visibleCards);

    return (
        <div className='h-100'>
            {loadingData ? (
                <Modal size="sm" centered show={true} >
                    <Modal.Body className=' bg-dark-mode-300 rounded-2 p-6 d-flex  flex-column align-items-center'>
                        <Spinner animation="border" size="lg" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <h5 className='mt-4'>Finding your matches</h5>
                        {false && <div className='p-4 mb-0 bg-dark-mode-800  rounded-2'>
                            {question}
                        </div>}
                    </Modal.Body>
                </Modal>
            ) : (
                <>

                    <form onSubmit={handleSubmit} className="scout-assist-form">
                        <div className="btn-group focus-dark active w-100">
                            <div className="btn btn-link border-1 border-neutral-gray-900 py-3 ps-4 pe-2 rounded-pill rounded-top-end-0 border-end-0 rounded-bottom-end-0 bg-dark-mode-50 small position-relative center-content">
                                <div className="bg-fluoro-green-500 rounded-circle badge mb-0">
                                    <i className="fa-regular fa-sparkles"></i>
                                </div>
                            </div>
                            <input
                                type="text"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                className="py-25 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-neutral-gray-900 rounded-pill form-control form-control-lg rounded-top-start-0 rounded-top-end-0 rounded-bottom-start-0 rounded-bottom-end-0"
                                placeholder="Find your perfect player..."
                            />
                            <button type="submit" className="btn btn-link pe-4 border-1 border-neutral-gray-900 rounded-pill rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50 small position-relative">
                                <i className="fa fa-arrow-right text-fluoro-green-400"></i>
                            </button>
                        </div>
                    </form>

                    {results.length > 0 ? (
                        <>


                            <div className='d-md-flex d-none justify-content-between align-items-center mb-5 ps-4 pt-3'>
                                <p className='mb-0'>
                                    Showing {((page - 1) * pageSize) + 1}-{Math.min(page * pageSize, resultsTotal)} of {resultsTotal} results
                                </p>
                                {
                                    exportLink &&
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className='mb-0 me-2 small'>Export player data</p>
                                        <a class="btn btn-md bg-neutral-gray-900 w-100 text-decoration-none small fw-semi-bold  rounded-2 w-auto" target='_blank' rel="noreferrer"  href={exportLink}><i class="fa-light fa-file-download"></i></a>
                                    </div>
                                }
                                {
                                    false && <div className='d-flex align-items-center max-w-px-md-140 w-100'>
                                        <select className="form-select form-select-md bg-neutral-gray-900 border-0 " onChange={orderByEventHandler}>
                                            <option value={'position'}>Position</option>
                                            <option value={'nationality'}>Nationality</option>
                                            <option value={'age'}>Age</option>
                                        </select>
                                    </div>
                                }
                            </div>

                            <div className='bg-dark-mode-700 p-md-4 p-2half rounded-2 mb-4 d-md-block d-none'>
                                <div className="d-grid-md grid-gap-4 grid-md-4-cols position-relative">
                                    {currentPageResults.map((value, index) => (
                                        <div key={index} onClick={() => handleCardClick(value)} className={'mb-2 mb-md-0 cursor-pointer d-flex flex-column align-items-center justify-content-between h-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none pt-2half pt-md-4 w-100 translate-icon-on-hover'}>
                                            <PlayerCard key={index} data={value} stats={Object.entries(value).filter(([key]) => !['id', 'first_name', 'last_name', 'image', 'uuid', 'team', 'team_name', 'team_id', 'team_image'].includes(key))} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='d-md-block d-none'>
                                <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                            </div>
                            <div className="d-md-none d-block">
                                <p className='fw-semi-bold mb-3 small'>Player results</p>
                                {
                                    exportLink &&
                                    <a className="btn btn-md center-content bg-neutral-gray-900 p-2half w-100 text-decoration-none small fw-semi-bold text-white rounded-2 mb-2half " target='_blank' rel="noreferrer"  href={exportLink}><i className="fa-light fa-file-download small pe-2"></i> Export</a>
                                }
                                {false &&
                                    <div className='d-grid grid-gap-2half grid-2-cols mb-2'>
                                        <div className='center-content'>
                                            <div className="btn btn-md center-content bg-neutral-gray-900 p-2half w-50 me-1 text-decoration-none small fw-semi-bold text-white rounded-2"><i className="fa-regular fa-share-nodes small pe-2"></i> Share</div>
                                            <div className="btn btn-md center-content bg-neutral-gray-900 p-2half w-50 text-decoration-none small fw-semi-bold text-white rounded-2"><i className="fa-regular fa-bookmark small pe-2"></i> Save</div>
                                        </div>
                                        <div className="btn btn-md center-content bg-fluoro-green-500 text-decoration-none small fw-semi-bold  rounded-2" onClick={handleShowFilterOffCanvas}><i className="ms-3 fa-regular fa-bars-filter small  me-2"></i>Filter results</div>
                                    </div>
                                }

                                <div className='d-grid grid-gap-2half grid-2-cols position-relative'>

                                    {mobileViewResults.map((value, index) => (
                                        <div key={index} onClick={() => handleCardClick(value)} className={'cursor-pointer d-flex flex-column align-items-center justify-content-between h-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none w-100 translate-icon-on-hover'}>
                                            <PlayerCard data={value} listingcards={true} stats={Object.entries(value).filter(([key]) => !['id', 'first_name', 'last_name', 'image', 'uuid', 'team', 'team_name', 'team_id', 'team_image'].includes(key))} />
                                        </div>
                                    ))}
                                </div>
                                {visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md bg-fluoro-green-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Load more
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (

                        <>
                            {searchParams.get('question') && <p className='small'>We were unable to find an answer to your question, please re-phrase it and try again.</p>}
                        </>
                    )}
                </>
            )}
            <Offcanvas className="min-vh-100 max-vh-100 min-vw-100 max-vw-100 bg-dark-mode-800 rounded-2" show={showFilterOffCanvas} onHide={handleCloseFilterOffCanvas} placement="end">
                <Offcanvas.Body className=' mt-3 mb-3 position-relative'>
                    <button type="button" className="d-none d-md-flex position-absolute z-index-1 end-0 top-0 disc-4 bg-zero-dark-300  disc-md-45 btn-close mt-n5 me-md-n3 me-n3"
                        onClick={handleCloseFilterOffCanvas} ></button>
                    <div className='d-block d-md-none my-4'>
                        <div
                            className="small flex-0 cursor-pointer "
                            onClick={handleCloseFilterOffCanvas}
                        ><i className="me-3 fa-light fa-arrow-left small"></i> Back to player listing</div>
                    </div>
                    <Filters />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default ScoutAssistSearch;
