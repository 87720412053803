import React, { useEffect, useState } from 'react';
import PlayerCard from '../templates/PlayerCard';
import { apiService } from '../services/apiService';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../templates/Pagination';
import Filters from './Filters';
import { Modal, Spinner, Offcanvas } from "react-bootstrap";

const ScoutAssistBasicSearch = ({ onError }) => {

    const { example } = useParams();

    const navigate = useNavigate();

    const handleCardClick = (player) => {
        navigate(`/playerdetail/${player.uuid}`);
    };

    const [loadingData, setLoadingData] = useState(true);
    const [results, setResults] = useState([]);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [pagesTotal, setPagesTotal] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    // const [orderBy, setOrderBy] = useState('Position');
    const [visibleCards, setVisibleCards] = useState(10);
    const [showFilterOffCanvas, setShowFilterOffCanvas] = useState(false);


    const handleCloseFilterOffCanvas = () => setShowFilterOffCanvas(false);
    const handleShowFilterOffCanvas = () => setShowFilterOffCanvas(true);

    const pageSizeEventHandler = (page_size) => {
        setPageSize(page_size);
        setPage(1);
    };

    const paginationEventHandler = (page) => {
        setPage(parseInt(page, 10));
    };

    const orderByEventHandler = (event) => {
        setPage(1);
        // setOrderBy(event.target.value);
    };

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + `/scout_assist/example${example}`)
            .then((data) => {
                setInterval(() => {
                    setResults(data);
                    setResultsTotal(data.length);
                    setPagesTotal(Math.ceil(data.length / pageSize));
                    setLoadingData(false);
              }, 750);

            
            })
            .catch((error) => {
                onError(error.message + ' error');
                setLoadingData(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    useEffect(() => {
        setPagesTotal(Math.ceil(resultsTotal / pageSize));
    }, [resultsTotal, pageSize]);

    const loadMoreHandler = () => {
        setVisibleCards((prev) => prev + 10);
    };

    const currentPageResults = results.slice((page - 1) * pageSize, page * pageSize);
    const mobileViewResults = results.slice(0, visibleCards);


    return (
        <div className='h-100'>
            {loadingData ? (
                <Modal size="sm" centered show={true} >
                    <Modal.Body className=' bg-dark-mode-300 rounded-2 p-4 d-flex  flex-column align-items-center'>
                        <Spinner animation="border" size="lg" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <h5 className='my-4'>Finding your matches</h5>
                        <div className='p-4 mb-0 bg-dark-mode-800  rounded-2'>
                            {('1' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.</p>}
                            {('2' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong>.</p>}
                            {('3' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>}
                            {('4' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>}
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                <>                    
                    <div className='max-w-f-650 border border-1 mb-5 border-neutral-gray-900 bg-dark-dark-mode-50 rounded-5 p-3 d-flex justify-content-between align-items-center'>
                        <div> 
                            <div className='bg-fluoro-green-400 disc-6 rounded-circle center-content me-3'><i className="fa-regular fa-sparkles text-black"></i></div>
                        </div>
                        {('1' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.</p>}
                        {('2' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong>.</p>}
                        {('3' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>}
                        {('4' === example) && <p className='mb-0 me-3'><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>}
                        <i className="icon text-fluoro-green-400 fa-solid fa-arrow-right small me-3"></i>
                    </div>
                    {results.length > 0 ? (
                        <>
                            <div className='d-md-flex d-none justify-content-between align-items-center mb-5 ps-4'>
                                <p className='mb-0'>
                                    Showing {((page - 1) * pageSize) + 1}-{Math.min(page * pageSize, resultsTotal)} of {resultsTotal} results
                                </p>
                                { 
                                false && <div className='d-flex align-items-center max-w-px-md-140 w-100'>
                                    <select className="form-select form-select-md bg-neutral-gray-900 border-0 " onChange={orderByEventHandler}>
                                        <option value={'position'}>Position</option>
                                        <option value={'nationality'}>Nationality</option>
                                        <option value={'age'}>Age</option>
                                    </select>
                                </div>
                                }
                            </div>
                            <div className='bg-dark-mode-700 p-md-4 p-2half rounded-2 mb-4 d-md-block d-none'>
                                <div className="d-grid-md grid-gap-4 grid-md-4-cols position-relative">
                                    {currentPageResults.map((value, index) => (
                                        <div key={index} onClick={() => handleCardClick(value)} className={'mb-2 mb-md-0 cursor-pointer d-flex flex-column align-items-center justify-content-between h-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none pt-2half pt-md-4 w-100 translate-icon-on-hover'}>
                                            <PlayerCard data={value} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='d-md-block d-none'>
                                <Pagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize} />
                            </div>
                            <div className="d-md-none d-block">
                                <p className='fw-semi-bold mb-3 small'>Player results</p>
                                <div className='d-grid grid-gap-2half grid-2-cols mb-2'>
                                    <div className='center-content'>
                                        <div className="btn btn-md center-content bg-neutral-gray-900 p-2half w-50 me-1 text-decoration-none small fw-semi-bold text-white rounded-2"><i className="fa-regular fa-share-nodes small pe-2"></i> Share</div>
                                        <div className="btn btn-md center-content bg-neutral-gray-900 p-2half w-50 text-decoration-none small fw-semi-bold text-white rounded-2"><i className="fa-regular fa-bookmark small pe-2"></i> Save</div>
                                    </div>
                                    <div className="btn btn-md center-content bg-fluoro-green-500 text-decoration-none small fw-semi-bold  rounded-2" onClick={handleShowFilterOffCanvas}><i className="ms-3 fa-regular fa-bars-filter small  me-2"></i>Filter results</div>
                                </div>
                                <div className='d-grid grid-gap-2half grid-2-cols position-relative'>

                                    {mobileViewResults.map((value, index) => (
                                        <div key={index} onClick={() => handleCardClick(value)} className={'cursor-pointer d-flex flex-column align-items-center justify-content-between h-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none w-100 translate-icon-on-hover'}>
                                            <PlayerCard data={value} listingcards={true} />
                                        </div>
                                    ))}
                                </div>
                                {visibleCards < resultsTotal && (
                                    <button onClick={loadMoreHandler} className="btn btn-md bg-fluoro-green-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold mt-3">
                                        Load more
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>No results</>
                    )}
                </>
            )}
            <Offcanvas className="min-vh-100 max-vh-100 min-vw-100 max-vw-100 bg-dark-mode-800 rounded-2" show={showFilterOffCanvas} onHide={handleCloseFilterOffCanvas} placement="end">
                <Offcanvas.Body className=' mt-3 mb-3 position-relative'>
                    <button type="button" className="d-none d-md-flex position-absolute z-index-1 end-0 top-0 disc-4 bg-zero-dark-300  disc-md-45 btn-close mt-n5 me-md-n3 me-n3"
                        onClick={handleCloseFilterOffCanvas} ></button>
                    <div className='d-block d-md-none my-4'>
                        <div
                            className="small flex-0 cursor-pointer "
                            onClick={handleCloseFilterOffCanvas}
                        ><i className="me-3 fa-light fa-arrow-left small"></i> Back to player listing</div>
                    </div>
                    <Filters />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default ScoutAssistBasicSearch;
