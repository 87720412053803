import React, { useState } from "react";
import ScoutAssist from '../components/ScoutAssist';
import OnesToWatch from '../components/OnesToWatch';
import Modal from "../templates/Modal";
import FeaturedNewsItem from "../components/FeaturedNewsItem";
import NewsGrid from "../components/NewsGrid";
import InsightSection from "../components/InsightsSection";

function Home() {

  const [modalMessage, setModalMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return <div>
    {showModal &&
      <Modal message={modalMessage} onClose={() => setShowModal(false)} />
    }
    <div className="my-5 my-md-7">
      <OnesToWatch onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
    <div className="mb-5 mb-md-7">
      <ScoutAssist />
    </div>
    <div className="mb-5 mb-md-7">
      <InsightSection onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
    <div className="d-grid-md grid-2-cols grid-gap-3 mb-5 mb-md-7">
      <FeaturedNewsItem onError={(message) => { setShowModal(true); setModalMessage(message) }} />
      <NewsGrid onError={(message) => { setShowModal(true); setModalMessage(message) }} />
    </div>
  </div>
}

export default Home;
