import { useState } from "react";
import { Modal } from "react-bootstrap";
import UpgradeModal from "../templates/UpgradeModal";
import { Link } from "react-router-dom";

export default function ScoutAssist({ props }) {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);


    return (
        <div>
            <div className="column-span-2">
                <div className="d-flex justify-content-between">
                    <p className="mb-2 large fw-semi-bold">Scout assist</p>
                    <Link to="/perfectplayer" className="text-decoration-none text-fluoro-green-500 d-none d-md-block small flex-0 fw-normal">Scout your perfect player<i className="ms-3 fa-light fa-arrow-right small"></i></Link>
                </div>
                <p className="mb-3">Helping you find the players that matter most to you and your team.</p>
            </div>
            <div className="d-flex overflow-scroll overflow-md-visible d-grid-md  grid-gap-3 grid-md-4-cols  pb-2 pb-md-0 position-relative" >
                <Link to={'/scoutassist_basic/1'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have scored at least a <strong className=" text-neutral-gray-500">goal per game</strong> this season.
                        </p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
                <Link to={'/scoutassist_basic/2'} onClick={handleShowModal} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong></p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
                <Link to={'/scoutassist_basic/3'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
                <Link to={'/scoutassist_basic/4'} className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                    <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>
                    <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                </Link>
            </div>
            <div className="d-block d-md-flex justify-content-center mt-3">
                <Link to="/perfectplayer" className="btn btn-md bg-fluoro-green-500  w-100 w-md-auto text-decoration-none small flex-0 fw-semi-bold">Scout your perfect player <i className="ms-3 fa-regular fa-arrow-right small"></i></Link>
            </div>
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3'>
                        <UpgradeModal />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
